$(document).ready(function(){
    var owl = $('.owl-carousel');
    owl.owlCarousel({
        items: 1,
        singleItem: true,
        pagination: false,
        loop:true,
        autoplay:true,
        autoplayTimeout:7000,
        autoplayHoverPause:false,
        animateOut: 'fadeOut',
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        nav:false,
    });
    $('.play').on('click',function(){
        owl.trigger('play.owl.autoplay',[7000])
    });
    $('.stop').on('click',function(){
        owl.trigger('stop.owl.autoplay')
    });
    // Overlay
    $('.closeOverlay').on('click',function () {
        $('.rmenu').removeClass('support');
        $('.smsForm').show();
    });
    $('.menu-item.support').on('click',function () {
        $('.rmenu').addClass('support');
        $('.smsForm').hide();
    });
    // Focus
    $('.signIn, .register, .select-wrapper, .requestP').on('click',function () {
        removeFocus();
        if ($(this).hasClass('signIn') || $(this).hasClass('register') || $(this).hasClass('requestP')) {
            $(this).addClass('focus');
        } else {
            $(this).parents('.signIn, .register, .requestP').addClass('focus');
        }
    });
    function removeFocus() {
        $('.signIn, .register, .requestP').removeClass('focus');
    }
    // reset password
    $('#resetPassword').on('click', function (e) {
        e.stopPropagation();
        $('.signIn').hide();
        $('label[for="usernameRequest"]').addClass('active');
        $('#usernameRequest').val($('#username').val());
        removeFocus();
        $('.requestP').addClass('focus');
        $('.requestP').show();
    });
    $('#rememberPassword').on('click', function (e) {
        e.stopPropagation();
        $('.requestP').hide();
        removeFocus();
        $('.signIn').addClass('focus');
        $('.signIn').show();
    });
    // register
    $('select[data-select-name="registerSelector"]').on('change',function () {
        window.location.href = this.options[this.selectedIndex].value;
    });
    // materialize select
    $('select').material_select();

    // Show flash modal & toast messages
    setTimeout(function(){
        $("#flash-modals .modal").each(function(){
            $(this).modal();
            $(this).modal('open');
        });

        $("#flash-toasts .toast").each(function(){
            Materialize.toast($(this).html(), 4000)
        });
    }, 500);

    $('form[name="send_sms_form"] .phoneEdit #send_sms_form_phone').focus(function (e) {
        if(!$('form[name="send_sms_form"] label[for="send_sms_form_phone"]').hasClass('active')){
            $('form[name="send_sms_form"] label[for="send_sms_form_phone"]').addClass('active');
        }
    });

    $('form[name="send_sms_form"] .phoneEdit #send_sms_form_phone').focusout(function (e) {
        if($('form[name="send_sms_form"] label[for="send_sms_form_phone"]').hasClass('active')){
            $('form[name="send_sms_form"] label[for="send_sms_form_phone"]').removeClass('active');
        }
    });

    $('.smsForm .close').on('click',function (e) {
        $('.smsForm').hide();
    });
});
$(document).on("click", ".select-wrapper", function (event) { event.stopPropagation(); });
$(document).on("click", ".input-field", function (event) { event.stopPropagation(); });
